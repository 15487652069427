<template>
  <v-row>
    <v-col>
      <v-card class="mx-auto" elevation="2" :loading="cargando">
        <v-img 
          :class="{
            'align-end': true,
            'white--text': this.tipoCurso == 3 || this.tipoCurso == 4,
          }"
          height="250px"
          :src="sitio.img"
          position="top"
          recaptchaaspect-ratio="1.4"
          contain
          style="background-position: right center !important;">
          <v-card-title>
            <div><br></div>
            <div v-html="sitio.titulo" />
          </v-card-title>
        </v-img>

        <v-card-text style="padding: 0 !important">
          <v-stepper v-model="e6" vertical flat v-if="cupo === '1'">
            <v-stepper-step :complete="e6 > 1" step="1">
              <h6 class="text-h6">Datos personales</h6>
              <small> Captura tus datos generales (Cerciórate de escribir correctamente tu nombre ya que tu constancia se generará con los datos que ingreses)</small>
            </v-stepper-step>

            <v-stepper-content step="1">
              <v-card class="mb-12 mt-2" flat>
                <v-row>
                  <v-col cols="12" sm="12" md="4">
                    <v-text-field
                      clearable
                      label="Nombre(s)"
                      placeholder="Escribe tu nombre"
                      v-model="formP.nombre"
                      outlined
                      counter
                      prepend-inner-icon="mdi-form-textarea"
                      :error-messages="nameErrors"
                      @input="$v.formP.nombre.$touch()"
                      @blur="$v.formP.nombre.$touch()"
                      @keyup="formP.nombre = formP.nombre.toUpperCase();"                      
                      class="code"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <v-text-field
                      clearable
                      counter
                      label="Primer apellido"
                      placeholder="Escribe tu primer apellido"
                      v-model="formP.appPat"
                      prepend-inner-icon="mdi-form-textarea"
                      outlined
                      :error-messages="appPatErrors"
                      @input="$v.formP.appPat.$touch()"
                      @blur="$v.formP.appPat.$touch()"
                      @keyup="formP.appPat = formP.appPat.toUpperCase();"
                      class="code"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <v-text-field
                      clearable
                      counter
                      label="Segundo apellido"
                      placeholder="Escribe tu segundo apellido"
                      prepend-inner-icon="mdi-form-textarea"
                      v-model="formP.appMat"
                      outlined
                      :error-messages="appMatErrors"
                      @input="$v.formP.appMat.$touch()"
                      @blur="$v.formP.appMat.$touch()"
                      @keyup="formP.appMat = formP.appMat.toUpperCase();"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="4">
                    <label for="">Sexo</label>
                    <v-radio-group
                      v-model="formP.sexo"
                      @input="$v.formP.sexo.$touch()"
                      @blur="$v.formP.sexo.$touch()"
                      :error-messages="sexoErrors"
                      row
                    >
                      <v-radio label="Hombre" value="0"></v-radio>
                      <v-radio label="Mujer" value="1"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <v-text-field
                      clearable
                      counter
                      label="RFC"
                      placeholder="Escribe tu RFC"
                      prepend-inner-icon="mdi-form-textarea"
                      v-model="formP.rfc"
                      outlined
                      hint="Escribe tu RFC con homoclave"
                      :error-messages="rfctErrors"
                      @input="$v.formP.rfc.$touch()"
                      @blur="$v.formP.rfc.$touch()"
                      @keyup="formP.rfc = formP.rfc.toUpperCase();"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <v-text-field
                      clearable
                      counter
                      label="CURP"
                      placeholder="Escribe tu CURP"
                      prepend-inner-icon="mdi-form-textarea"
                      v-model="formP.curp"
                      outlined
                      hint="Escribe tu CURP a 18 dígitos"
                      :error-messages="curpErrors"
                      @input="$v.formP.curp.$touch()"
                      @blur="$v.formP.curp.$touch()"
                      @keyup="formP.curp = formP.curp.toUpperCase();"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card>
              <v-btn
                color="primary"
                @click="e6 = 2"
                :disabled="$v.formP.$invalid"
              >
                Continuar
              </v-btn>
            </v-stepper-content>

            <v-stepper-step :complete="e6 > 2" step="2">
              <h6 class="text-h6">Datos escolares</h6>
              <small>Captura los datos del CCT</small>
            </v-stepper-step>

            <v-stepper-content step="2">
              <v-card flat>
                <v-row>
                  <v-col cols="12" sm="12" md="4">
                    <label for="">Nivel del CCT</label>
                    <v-radio-group v-model="form.nivel" column>
                      <v-radio
                        v-for="n in niveles"
                        :key="n.value"
                        :label="n.tipo"
                        :value="n.value"
                      >
                      </v-radio>
                    </v-radio-group>
                    <div v-if="form.nivel == 6" class="mt-2">
                      <v-text-field
                        clearable
                        counter
                        label="Especifíque que otro nivel"
                        placeholder="Escribe que otra nivel"
                        prepend-inner-icon="mdi-form-textarea"
                        v-model="form.otroNivel"
                        outlined
                        :error-messages="otroNivelErrors"
                        @input="$v.form.otroNivel.$touch()"
                        @blur="$v.form.otroNivel.$touch()"
                        @keyup="form.otroNivel = form.otroNivel.toUpperCase();"
                      ></v-text-field>
                      <v-text-field
                        clearable
                        counter
                        label="Especifíque que otra modalidad"
                        placeholder="Escribe que otra modalidad"
                        prepend-inner-icon="mdi-form-textarea"
                        v-model="form.otraModalidad"
                        outlined
                        :error-messages="otraModalidadErrors"
                        @input="$v.form.otraModalidad.$touch()"
                        @blur="$v.form.otraModalidad.$touch()"
                        @keyup="form.otraModalidad = form.otraModalidad.toUpperCase();"
                      ></v-text-field>
                    </div>
                    <div v-if="isSecundaria" class="mt-2">
                      <label for="">Tipo de secundaria</label>
                      <v-radio-group v-model="form.secundariaNivel" column>
                        <v-radio label="General" value="0"> </v-radio>
                        <v-radio label="Ténica" value="1"> </v-radio>
                        <v-radio label="Telesecundaria" value="2"> </v-radio>
                      </v-radio-group>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <label for="">Sostenimiento</label>
                    <v-radio-group v-model="form.sostenimiento" row>
                      <v-radio label="Federal" value="2"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <label for="">Función</label>
                    <v-checkbox
                      v-for="f in funciones"
                      :key="f.value"
                      v-model="form.funcion"
                      :label="f.tipo"
                      color="primary"
                      :value="f.value"
                      hide-details
                    ></v-checkbox>
                    <div v-if="isOtraFuncion" class="mt-2">
                      <v-text-field
                        clearable
                        counter
                        label="Especifíque que otra función"
                        placeholder="Escribe que otra función"
                        prepend-inner-icon="mdi-form-textarea"
                        v-model="form.otraFuncion"
                        outlined
                        :error-messages="otraFuncionErrors"
                        @input="$v.form.otraFuncion.$touch()"
                        @blur="$v.form.otraFuncion.$touch()"
                        @keyup="form.otraFuncion = form.otraFuncion.toUpperCase();"
                      ></v-text-field>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="4">
                    <v-text-field
                      clearable
                      counter
                      label="CCT"
                      placeholder="Escribe el CCT de tu escuela"
                      prepend-inner-icon="mdi-form-textarea"
                      v-model="form.cct"
                      outlined
                      :error-messages="cctErrors"
                      @input="$v.form.cct.$touch()"
                      @blur="$v.form.cct.$touch()"
                      @keyup="form.cct = form.cct.toUpperCase();"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <v-select
                      :items="municipios"
                      label="Municipio del CCT"
                      v-model="form.municipio"
                      prepend-inner-icon="mdi-format-list-checkbox"
                      outlined
                      item-text="text"
                      item-value="value"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <v-text-field
                      clearable
                      counter
                      label="Localidad CCT"
                      placeholder="Localidad del CCT"
                      prepend-inner-icon="mdi-form-textarea"
                      v-model="form.cctLocalidad"
                      outlined
                      :error-messages="localidadErrors"
                      @input="$v.form.cctLocalidad.$touch()"
                      @blur="$v.form.cctLocalidad.$touch()"
                      @keyup="form.cctLocalidad = form.cctLocalidad.toUpperCase();"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="4">
                    <v-select
                      :items="regiones"
                      label="Region del CCT"
                      v-model="form.region"
                      prepend-inner-icon="mdi-format-list-checkbox"
                      outlined
                      item-text="tipo"
                      item-value="value"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <v-text-field
                      clearable
                      counter
                      label="Zona escolar"
                      placeholder="Escribe Zona escolar 0000"
                      prepend-inner-icon="mdi-form-textarea"
                      v-model="form.zona"
                      outlined
                      :error-messages="zonaErrors"
                      @input="$v.form.zona.$touch()"
                      @blur="$v.form.zona.$touch()"
                      @keyup="form.zona = form.zona.toUpperCase();"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card>
              <v-btn
                color="primary"
                @click="e6 = 3"
                :disabled="$v.form.$invalid">
                Continuar
              </v-btn>
              <v-btn text @click="e6 = 1"> Regresar </v-btn>
            </v-stepper-content>

            <v-stepper-step :complete="e6 > 3" step="3">
              <h6 class="text-h6">Datos de contacto</h6>

              <small>Captura tus datos de contacto</small>
            </v-stepper-step>

            <v-stepper-content step="3">
              <v-card flat class="mb-5 mt-3">
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      clearable
                      counter
                      label="Teléfono Celular"
                      placeholder="Escribe tu teléfono"
                      prepend-inner-icon="mdi-phone-dial-outline"
                      hint="El número debe ser a 10 dígitos"
                      v-model="formC.telefono"
                      outlined
                      :error-messages="telefonoErrors"
                      @input="$v.formC.telefono.$touch()"
                      @blur="$v.formC.telefono.$touch()"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      clearable
                      counter
                      :type="hideEmail ? 'password' : 'email'"
                      label="Correo electrónico (que revises con frecuencia)"
                      placeholder="Escribe tu correo"
                      prepend-inner-icon="mdi-email-edit-outline"
                      hint="El correo electrónico será tu usuario para la plataforma"
                      v-model="formC.correo"
                      outlined
                      :error-messages="correoErrors"
                      @input="$v.formC.correo.$touch()"
                      @blur="$v.formC.correo.$touch();toggleEmailVisibility()"
                      @keyup="formC.correo = formC.correo.toLowerCase();toggleEmailVisibility2()"
                    ></v-text-field>
                  </v-col>
                </v-row>
              <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      clearable
                      counter
                      label="Confirmar Teléfono"
                      placeholder="Escribe tu teléfono"
                      prepend-inner-icon="mdi-phone-dial-outline"
                      hint="El número debe ser a 10 dígitos"
                      v-model="formC.telefono2"
                      outlined
                      :error-messages="telefono2Errors"
                      @input="$v.formC.telefono2.$touch()"
                      @blur="$v.formC.telefono2.$touch()"
                      @paste.prevent
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      clearable
                      counter
                      type="email"
                      label="Confirmar correo electrónico (que revises con frecuencia)"
                      placeholder="Escribe tu correo"
                      prepend-inner-icon="mdi-email-edit-outline"
                      hint="El correo electrónico será tu usuario para la plataforma"
                      v-model="formC.correo2"
                      outlined
                      :error-messages="correo2Errors"
                      @input="$v.formC.correo2.$touch()"
                      @blur="$v.formC.correo2.$touch()"
                      @paste.prevent
                      @keyup="formC.correo2 = formC.correo2.toLowerCase();"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <h6 class="text-h6 text-center">
                     &#8986; Al concluir tu registro revisa tu correo electrónico y no olvides buscar en tu BANDEJA DE CORREO NO DESEADO (SPAM) &#128221; </h6>
                  </v-col>
                </v-row>
              </v-card>
              <v-btn
                color="primary"
                @click="registrar"
                :disabled="registroBand">
                Terminar registro
              </v-btn>
              <v-btn text @click="e6 = 2"> Regresar </v-btn>
            </v-stepper-content>
          </v-stepper>
          <div v-else-if="cupo === '2'" class="text-center">
            <v-icon x-large color="primary darken-2" class="pa-2"> mdi-tray-full </v-icon>
            <p class="pa-2 title">El cupo para este curso ha sido alcanzado</p> 
          </div>
          <div v-else-if="cupo === '3'" class="text-center">
            <v-icon x-large color="primary darken-2" class="pa-2"> mdi-tray-full </v-icon>
            <p class="pa-2 title">En estos momentos la inscripción aún no se encuentra activa. Te recordamos que el periodo de registro es del 28 de abril al 04 de mayo 2023.</p>
            <p class="pa-2 title"><a href="https://didactica.plataformaedu.com.mx">https://didactica.plataformaedu.com.mx</a></p>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-dialog v-model="cargando" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Cargando..
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogRegistro" persistent max-width="650">
      <v-card>
        <v-card-title class="headline">
          {{ titloDialog }}
        </v-card-title>
        <v-card-text>
          <span v-html="textoDialog"></span>
          </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cerrarModal"> Aceptar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
// import VueRecaptcha from "vue-recaptcha";
import { mapState } from "vuex";
import {
  required,
  minLength,
  maxLength,
  numeric,
  helpers,
  alphaNum,
  requiredIf,
  sameAs
} from "vuelidate/lib/validators";

const alphaDiacritic = helpers.regex("alphaDiacritic", /^[a-zA-ZÀ-ž\s]*$/);
const customEmail = helpers.regex(
  "customEmail",
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

const validorCCT = function(){
      let dt = this.form.cct.substr(0, 5);
      

      let dtx = this.form.cct.substr(0, 6); //AGREGADO 18/06/2023 (Caso especial SUPERVISIÓN DE ZONA PREESCOLAR FEDERAL y DEPARTAMENTO DE OPERACIONES DE PROGRAMAS TECNICO PEDAGOGICOS DE TELESECUNDARIAS FEDERAL)


      let val = false;
      if(dt=="30DBA"){val = true}
      if(dt=="30DBT"){val = true}
      if(dt=="30DCC"){val = true}
      if(dt=="30DCI"){val = true}
      if(dt=="30DDI"){val = true}
      if(dt=="30DES"){val = true}
      if(dt=="30DIN"){val = true}
      if(dt=="30DJN"){val = true}
      if(dt=="30DML"){val = true}
      if(dt=="30DPB"){val = true}
      if(dt=="30DPR"){val = true}
      if(dt=="30DSM"){val = true}
      if(dt=="30DSN"){val = true}
      if(dt=="30DST"){val = true}
      if(dt=="30DTV"){val = true}
      if(dt=="30DZC"){val = true}
      if(dt=="30FEI"){val = true}
      if(dt=="30FES"){val = true}
      if(dt=="30FRB"){val = true}
      if(dt=="30FUA"){val = true}
      if(dt=="30FSE"){val = true}
      if(dt=="30FEJ"){val = true}
      if(dt=="30FJS"){val = true}
      if(dt=="30FIZ"){val = true}
      if(dt=="30FTS"){val = true}
      if(dt=="30FTV"){val = true}
      if(dt=="30FJT"){val = true}
      if(dt=="30FZT"){val = true}
      //AGREGADOS 18/06/2023
      if(dt=="30FLS"){val = true}
      if(dt=="30FJZ"){val = true}
      if(dtx=="30FZP0"){val = true} // (Caso especial SUPERVISIÓN DE ZONA PREESCOLAR FEDERAL)
      if(dt=="30FJE"){val = true}
      if(dt=="30FIS"){val = true}
      if(dtx=="30FTP0"){val = true} // (Caso especial DEPARTAMENTO DE OPERACIONES DE PROGRAMAS TECNICO PEDAGOGICOS DE TELESECUNDARIAS FEDERAL)
      if(dt=="30FFS"){val = true}
      if(dt=="30FZF"){val = true}

      return val
      /*
      let dt = this.form.cct.substr(0, 2);
      if(dt=="30"){val = true}
      return val
      */
}
export default {
  name: "Home",
  components: {
    // "vue-recaptcha": VueRecaptcha,
  },
  mounted() {
    this.obtenerMunicipios();
    this.validarCupo();
    this.bienvenida();
    if (this.tipoCurso == 1 || this.tipoCurso == 2) {
      this.$vuetify.theme.themes.light.primary = "#784521";
    }
    if (this.tipoCurso == 4) {
      this.$vuetify.theme.themes.light.primary = "#3d93bb";
    }
    if (this.tipoCurso == 6) {
      this.$vuetify.theme.themes.light.primary = "#9D2A26";
    }
  },
  data() {
    return {
      hideEmail: false,
      cupo: 3,
      captcha: false,
      key: "6LfVVd8aAAAAAM5YfFCoUbcgt_DJZgif3FPef51b",
      cargando: false,
      dialogRegistro: false,
      registroExitoso: false,
      titloDialog: "",
      textoDialog: "",
      urlRedireccion: "",
      e6: 1,
      municipios: [],
      niveles: [
        {
          tipo: "Educación inicial",
          value: 0,
        },
        {
          tipo: "Preescolar",
          value: 1,
        },
        {
          tipo: "Primaria",
          value: 2,
        },
        {
          tipo: "Secundaria",
          value: 3,
        },
        {
          tipo: "Educación especial",
          value: 4,
        },
        {
          tipo: "Educación indígena",
          value: 5,
        },
        {
          tipo: "Otro nivel",
          value: 6,
        },
      ],
      funciones: [
        {
          tipo: "Jefe de Sector",
          value: 0,
        },
        {
          tipo: "Jefe de Enseñanza",
          value: 1,
        },
        {
          tipo: "Supervisor Escolar",
          value: 2,
        },
        {
          tipo: "Director",
          value: 3,
        },
        {
          tipo: "Subdirector",
          value: 4,
        },
        {
          tipo: "Docente",
          value: 5,
        },
        {
          tipo: "Asesor Técnico Pedagógico",
          value: 6,
        },
        {
          tipo: "Otro",
          value: 7,
        },
      ],
      regiones:[
        {
          tipo: "HUASTECA ALTA",
          value: 1,
        },
        {
          tipo: "HUASTECA BAJA",
          value: 2,
        },
        {
          tipo: "TOTONACA",
          value: 3,
        },
        {
          tipo: "NAUTLA",
          value: 4,
        },
        {
          tipo: "CAPITAL",
          value: 5,
        },
        {
          tipo: "LAS MONTAÑAS",
          value: 6,
        },
        {
          tipo: "SOTAVENTO",
          value: 8,
        },
        {
          tipo: "PAPALOAPAN",
          value: 9,
        },
        {
          tipo: "LOS TUXTLAS",
          value: 10,
        },
        {
          tipo: "OLMECA",
          value: 11,
        },
      ],
      formP: {
        nombre: "",
        appPat: "",
        appMat: "",
        sexo: "",
        rfc: "",
        curp: "",
      },
      form: {
        nivel: "",
        otroNivel: "",
        otraModalidad: "",
        secundariaNivel: "",
        sostenimiento: "",
        funcion: [],
        otraFuncion: "",
        cct: "",
        cctLocalidad: "",
        municipio: "",
        zona: "",
        region:"",
      },
      formC: {
        telefono: "",
        telefono2: "",
        correo: "",
        correo2: ""
      },
    };
  },
  computed: {
    ...mapState({ tipoCurso: "tipoCurso" }),
    sitio() { 
      switch (this.tipoCurso) {
      case 1:
          return {
            titulo:"Cultura de paz, ambientes escolares favorables para el aprendizaje efectivo",
            url: "https://registro.plataformaedu.com.mx/api/index.php",
            urlCupo: "https://registro.plataformaedu.com.mx/api/cupo.php",
            urlCatalogo:"https://registro.plataformaedu.com.mx/api/municipios.php",
            img: "https://registro.plataformaedu.com.mx/api/Banner_cultura.png",
            width: 120,
          };    
        default:
          return {
            img: "",
            width: 0,
          };
      }
    },
    registroBand() {
      if (this.$v.formC.$invalid) {
        return true;
      } else if (this.cargando) {
        return true;
      } else {
        return false;
      }
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.formP.nombre.$dirty) return errors;
      !this.$v.formP.nombre.minLength &&
        errors.push("El campo debe tener mínimo 3 letras");
      !this.$v.formP.nombre.required && errors.push("El campo es obligatorio.");
      !this.$v.formP.nombre.alphaDiacritic &&
        errors.push("El campo no puede tener caractéres especiales.");
      return errors;
    },
    appPatErrors() {
      const errors = [];
      if (!this.$v.formP.appPat.$dirty) return errors;
      !this.$v.formP.appPat.minLength &&
        errors.push("El campo debe tener mínimo 3 letras");
      !this.$v.formP.appPat.required && errors.push("El campo es obligatorio.");
      !this.$v.formP.appPat.alphaDiacritic &&
        errors.push("El campo no puede tener caractéres especiales.");
      return errors;
    },
    appMatErrors() {
      const errors = [];
      if (!this.$v.formP.appMat.$dirty) return errors;
      !this.$v.formP.appMat.minLength &&
        errors.push("El campo debe tener mínimo 3 letras");
      !this.$v.formP.appMat.required && errors.push("El campo es obligatorio.");
      !this.$v.formP.appMat.alphaDiacritic &&
        errors.push("El campo no puede tener caractéres especiales.");
      return errors;
    },
    sexoErrors() {
      const errors = [];
      if (!this.$v.formP.sexo.$dirty) return errors;
      !this.$v.formP.sexo.required && errors.push("El campo es obligatorio.");
      return errors;
    },
    rfctErrors() {
      const errors = [];
      if (!this.$v.formP.rfc.$dirty) return errors;
      !this.$v.formP.rfc.minLength &&
        errors.push("El campo debe tener mínimo 13 letras");
      !this.$v.formP.rfc.maxLength &&
        errors.push("El campo debe tener máximo 13 letras");
      !this.$v.formP.rfc.required && errors.push("El campo es obligatorio.");
      !this.$v.formP.rfc.alphaNum &&
        errors.push("El campo no puede tener caractéres especiales.");
      return errors;
    },
    curpErrors() {
      const errors = [];
      if (!this.$v.formP.curp.$dirty) return errors;
      !this.$v.formP.curp.minLength &&
        errors.push("El campo debe tener mínimo 18 letras");
      !this.$v.formP.curp.maxLength &&
        errors.push("El campo debe tener máximo 18 letras");
      !this.$v.formP.curp.required && errors.push("El campo es obligatorio.");
      !this.$v.formP.curp.alphaNum &&
        errors.push("El campo no puede tener caractéres especiales.");
      return errors;
    },
    isOtroNivel() {
      return this.form.nivel == 6;
    },
    isSecundaria() {
      return this.form.nivel == 3;
    },
    isOtraFuncion() {
      return this.form.funcion.includes(7);
    },
    otroNivelErrors() {
      const errors = [];
      if (!this.$v.form.otroNivel.$dirty) return errors;
      !this.$v.form.otroNivel.minLength &&
        errors.push("El campo debe tener mínimo 4 letras");
      !this.$v.form.otroNivel.required &&
        errors.push("El campo es obligatorio.");
      !this.$v.form.otroNivel.alphaDiacritic &&
        errors.push("El campo no puede tener caractéres especiales.");
      return errors;
    },
    otraModalidadErrors() {
      const errors = [];
      if (!this.$v.form.otraModalidad.$dirty) return errors;
      !this.$v.form.otraModalidad.minLength &&
        errors.push("El campo debe tener mínimo 4 letras");
      !this.$v.form.otraModalidad.required &&
        errors.push("El campo es obligatorio.");
      !this.$v.form.otraModalidad.alphaDiacritic &&
        errors.push("El campo no puede tener caractéres especiales.");
      return errors;
    },
    otraFuncionErrors() {
      const errors = [];
      if (!this.$v.form.otraFuncion.$dirty) return errors;
      !this.$v.form.otraFuncion.minLength &&
        errors.push("El campo debe tener mínimo 4 letras");
      !this.$v.form.otraFuncion.required &&
        errors.push("El campo es obligatorio.");
      !this.$v.form.otraFuncion.alphaDiacritic &&
        errors.push("El campo no puede tener caractéres especiales.");
      return errors;
    },
    cctErrors() {
      const errors = [];
      if (!this.$v.form.cct.$dirty) return errors;
      !this.$v.form.cct.minLength && errors.push("El campo debe tener mínimo 10 letras");
      !this.$v.form.cct.maxLength && errors.push("El campo debe tener máximo 10 letras");
      !this.$v.form.cct.required && errors.push("El campo es obligatorio.");
      !this.$v.form.cct.alphaNum && errors.push("El campo no puede tener caractéres especiales.");
      !this.$v.form.cct.validorCCT && errors.push("Beneficio para CCT Federales");
      return errors;
    },
    localidadErrors() {
      const errors = [];
      if (!this.$v.form.cctLocalidad.$dirty) return errors;
      !this.$v.form.cctLocalidad.minLength &&
        errors.push("El campo debe tener mínimo 4 letras");
      !this.$v.form.cctLocalidad.required &&
        errors.push("El campo es obligatorio.");
      !this.$v.form.cctLocalidad.alphaDiacritic &&
        errors.push("El campo no puede tener caractéres especiales.");
      return errors;
    },
    zonaErrors() {
      const errors = [];
      if (!this.$v.form.zona.$dirty) return errors;
      !this.$v.form.zona.minLength &&
        errors.push("El campo debe tener mínimo 2 letras");
      !this.$v.form.zona.required && errors.push("El campo es obligatorio.");
      !this.$v.form.zona.numeric && errors.push("El campo solo es numérico");
      return errors;
    },
    telefonoErrors() {
      const errors = [];
      if (!this.$v.formC.telefono.$dirty) return errors;
      !this.$v.formC.telefono.minLength &&
        errors.push("El campo debe tener mínimo 10 dígitos");
      !this.$v.formC.telefono.maxLength &&
        errors.push("El campo debe tener máximo 10 dígitos");
      !this.$v.formC.telefono.required &&
        errors.push("El campo es obligatorio.");
      !this.$v.formC.telefono.numeric &&
        errors.push("El campo solo puede contener números");
      return errors;
    },
    telefono2Errors(){
      const errors = [];
        if (!this.$v.formC.telefono2.$dirty) return errors;
      !this.$v.formC.telefono2.sameAstelefono &&
        errors.push("El teléfono debe coincidir");
      return errors;
    },
    correoErrors() {
      const errors = [];
      if (!this.$v.formC.correo.$dirty) return errors;
      !this.$v.formC.correo.required && errors.push("El campo es obligatorio.");
      !this.$v.formC.correo.customEmail &&
        errors.push("El campo debe ser un correo valido");
      return errors;
    },
    correo2Errors(){
      const errors = [];
      if (!this.$v.formC.correo2.$dirty) return errors;
      !this.$v.formC.correo2.sameAscorreo &&
        errors.push("El correo debe coincidir");
      return errors;
    },
  },
  validations: {
    formP: {
      nombre: { required, minLength: minLength(3), alphaDiacritic },
      appPat: { required, minLength: minLength(3), alphaDiacritic },
      appMat: { required, minLength: minLength(3), alphaDiacritic },
      sexo: { required },
      rfc: {
        required,
        minLength: minLength(13),
        maxLength: maxLength(13),
        alphaNum,
      },
      curp: {
        required,
        minLength: minLength(18),
        maxLength: maxLength(18),
        alphaNum,
      },
    },
    form: {
      nivel: { required },
      otroNivel: {
        required: requiredIf(function () {
          return this.isOtroNivel;
        }),
        minLength: minLength(4),
        alphaDiacritic,
      },
      otraModalidad: {
        required: requiredIf(function () {
          return this.isOtroNivel;
        }),
        minLength: minLength(4),
        alphaDiacritic,
      },
      secundariaNivel: {
        required: requiredIf(function () {
          return this.isSecundaria;
        }),
      },
      sostenimiento: { required },
      funcion: { required },
      otraFuncion: {
        required: requiredIf(function () {
          return this.isOtraFuncion;
        }),
        minLength: minLength(4),
        alphaDiacritic,
      },
      cct: {
        required,
        alphaNum,
        minLength: minLength(10),
        maxLength: maxLength(10),
        validorCCT,
      },
      cctLocalidad: { required, minLength: minLength(4), alphaDiacritic },
      municipio: { required },
      region: { required },
      zona: { required, minLength: minLength(2), numeric },
    },
    formC: {
      telefono: {
        required,
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
      telefono2:{ required, sameAstelefono: sameAs("telefono")  },
      correo: { customEmail, required },
      correo2:{ required, sameAscorreo: sameAs("correo")  },
    },
  },
  methods: {
    toggleEmailVisibility() {
      if (this.formC.correo !== '') {
        this.hideEmail = !this.hideEmail;
      } else {
        this.hideEmail = false;
      }
    },
    toggleEmailVisibility2() {
      if (this.formC.correo == '') {
        this.hideEmail = false;
      } 
    },
    validarCupo() {
      this.cargando = true;
      fetch(this.sitio.urlCupo, {
        method: "POST",
        body: JSON.stringify({
          tipoCurso: this.tipoCurso,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          this.cupo = res.message;
        })
        .then(({ success }) => {
          
          this.cargando = success;
          console.log(this.cupo);
        })
        .catch((err) => {
          console.warn(err);
          this.cargando = false;
        });
    },
    bienvenida(){
      this.dialogRegistro = true;
      this.titloDialog = "Mensaje Importante";
      this.textoDialog = "<p style='text-align: justify;'>Manifiesto mi responsabilidad para dar seguimiento a la presente inscripción del curso revisando mi correo electrónico periódicamente para las notificaciones correspondientes, así mismo, me comprometo a concluir el curso <b> Cultura de paz, ambientes escolares favorables para el aprendizaje efectivo</b> dentro de las fechas estipuladas pues reconozco que ocupo un lugar privilegiado dentro de los únicos 1,000 espacios disponibles.</p>";
    },
    cerrarModal() {
      this.dialogRegistro = false;
      if(this.registroExitoso){
        window.location = this.urlRedireccion;
      }
    },
    onVerify: function (response) {
      if (response) {
        this.captcha = true;
      }
    },
    onExpired: function () {
      console.log("Expired");
      this.captcha = false;
    },
    obtenerMunicipios() {
      this.cargando = true;
      console.log("prueba error: " + this.sitio.urlCatalogo);
      fetch(this.sitio.urlCatalogo)
        .then((res) => res.json())
        .then((res) => {
          console.log("respuesta: " + res);
          this.cargando = false;
          this.municipios = res.data;
        })
        .catch((err) => {
          this.cargando = false;
          console.error(err);
        });
    },
    registrar() {
      this.cargando = true;
      let data = this.formP;
      data = { ...data, ...this.form };
      data = { ...data, ...this.formC };
      data.funcion = this.form.funcion.toString();
      data.tipoCurso = this.tipoCurso;
      console.log(data);

      fetch(this.sitio.url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())
        .then((res) => {
          this.cargando = false;
          if (res.success) {
            this.dialogRegistro = true;
            this.titloDialog = "Se ha registrado con éxito";
            this.textoDialog = res.message;
            this.urlRedireccion = res.url
            this.registroExitoso = true;
          } else {
            this.dialogRegistro = true;
            this.titloDialog = "Error";
            this.textoDialog = res.message;
          }
        })
        .catch((err) => {
          console.error(err);
          this.cargando = false;
          this.dialogRegistro = true;
          this.titloDialog = "Error";
          this.textoDialog =
            "Error de conexión con el servidor, intente de nuevo más tarde";
        });
    },
  },
  onPaste:function(){
    console.log('Text Pasted');
  }
};

</script>
<style scoped>
label {
  font-size: 18px;
}
.v-card__title {
  word-break: break-word;
}
.code input {
  text-transform: uppercase;
}

</style>